import { FunctionComponent } from "react";
import styles from "./ListItem.module.scss";

interface Props {
  title: string;
  value: string | number;
}

export const ListItem: FunctionComponent<Props> = ({ title, value }) => {
  return (
    <div className={styles.item}>
      <div className={styles.key}>{title}</div>
      <div className={styles.value}>{String(value)}</div>
    </div>
  );
};
